<template>
  <div>

    <b-modal
      id="modal-medicine"
      size="xl"
      title="Pilih Obat"
      hide-footer
    >
      <div class="row">
        <div class="col-md-9">
          <Table
            purpose="modal"
            modalPurpose="modal"
            :isHospitalTransaction="isHospitalTransaction"
            :selectedItem="selectedItem"
            :checkbox="checkbox"   
            :isSortcut ="false"
            :isAbjad="true"
            @chooseMedicine="chosenMedicine"
            @packageMedicine="packageMedicine"
            :secondPurpose="secondPurpose"
          />
        </div>
        <div class="col-md-3">
          <b-table
            striped
            hover
            :items="selectedMedicine"
            :fields="fields"
            responsive
          >
            <!-- :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '' , width: field.key === 'responsible_doctor' ? '10%' : '' }" -->
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'actions'
                      ? purpose == 'master'
                        ? '17%'
                        : ''
                      : '',
                  width: field.key == 'generic_name' ? '15%' : '',
                }"
              />
            </template>
            <template #cell(name)="data">
              <span>{{data.item.name}} <b>({{data.item.quantity}})</b></span>
            </template>
            <template #cell(actions)="data">
               <b-button
                size="sm"
                class="btn-danger mr-1"
                style="padding:5px !important"
                
                @click="deleteData(data.item.id)"
                ><i class="fas fa-trash px-0"></i
              ></b-button>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>

import Table from '@/component/medicine/TableMedicineAction.vue'

export default {

  props: {
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    isSortcut: Boolean,
    secondPurpose:{
      type: String,
      default: 'listing'
    },
    selectedMedicine: Array
  },

  data(){
    return{

      selectedData: [],
      // fields
       fields: [
        {
          key: "actions",
          label: "",
          thClass: "nowrap",
          tdClass: "p-1",
        },
        {
          key: "name",
          label: "Obat & alkes dipakai(Jml)",
          sortable: true,
          thClass: "nowrap",
          tdClass: "p-1 nowrap",
        },
      ],
    }
  },

  components: {
    Table
  },

  methods: {
    deleteData(id){
      let a;
      for (a = 0; a < this.selectedMedicine.length; a++) {
        if (this.selectedMedicine[a].id == id) {
          this.selectedMedicine.splice(a, 1);
        }
      }
      // this.selectedItem = this.selectedMedicine
      this.$root.$emit("updateMedicine",this.selectedMedicine)
      this.$emit("packageMedicine", this.selectedMedicine); 
    },

    chosenMedicine(value) {
      this.$emit('chosenMedicine', value)
      console.info(value,"val chosen");
      this.selectedData.push(value)
    },
    
   packageMedicine(datas) {
      this.$emit("packageMedicine", datas); 
      this.selectedData = datas
    },


  },
  mounted(){
    
  }

 

}
</script>

<style>
</style>